var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[(_vm.initBrand && _vm.initBrand.id)?_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Изменить бренд")])])]),_c('ValidationObserver',{ref:"editBrandForm",attrs:{"slim":true}},[_c('md-card-content',[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,false,4086163274)}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Лого")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}})],1)]}}],null,false,3189629574)}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Статус")]),_c('md-select',{on:{"focus":reset},model:{value:(_vm.is_active),callback:function ($$v) {_vm.is_active=$$v},expression:"is_active"}},[_c('md-option',{attrs:{"value":1,"placeholder":"Статус"}},[_vm._v("Активен")]),_c('md-option',{attrs:{"value":0}},[_vm._v("Не активен")])],1)],1)]}}],null,false,3297059285)}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Тип")]),_c('md-select',{on:{"focus":reset},model:{value:(_vm.is_main),callback:function ($$v) {_vm.is_main=$$v},expression:"is_main"}},[_c('md-option',{attrs:{"value":1,"placeholder":"Тип"}},[_vm._v("Основной")]),_c('md-option',{attrs:{"value":0}},[_vm._v("Не основной")])],1)],1)]}}],null,false,3401016274)}),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.editBrand}},[_vm._v(" Сохранить ")])],1)],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }