<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="initBrand && initBrand.id">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить бренд</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editBrandForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model="name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Лого</label>
                <md-input v-model="logo" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус</label>
                <md-select v-model="is_active" @focus="reset">
                  <md-option :value="1" placeholder="Статус">Активен</md-option>
                  <md-option :value="0">Не активен</md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Тип</label>
                <md-select v-model="is_main" @focus="reset">
                  <md-option :value="1" placeholder="Тип">Основной</md-option>
                  <md-option :value="0">Не основной</md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="editBrand">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      name: "",
      logo: "",
      is_active: null,
      is_main: null,
    };
  },

  computed: {
    ...mapState({
      initBrand: (state) => state.brands.brand,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getBrand(+this.$route.params.id);
    if (success) {
      this.name = this.initBrand.name;
      this.logo = this.initBrand.logo;
      this.is_active = this.initBrand.is_active;
      this.is_main = this.initBrand.is_main;
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("brands", ["getBrand", "updateBrand"]),

    async editBrand() {
      const isValid = await this.$refs.editBrandForm.validate();
      if (!isValid) {
        return;
      }

      let brand = {
        name: this.name,
        logo: this.logo,
        is_active: this.is_active,
        is_main: this.is_main,
      };

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateBrand({
        id: this.initBrand.id,
        brand,
      });

      if (success) {
        this.$router.push("/brands");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>